.content {
    max-width: 100%;
    margin: 0 auto;
    display: block;
    grid-template-columns: 300px 1fr;
  }
  
  .information {
    background: lime;
    order: 2;
  }
  
  .menu {
    background: lightblue;
  }
  
  @media all and (min-width: 992px) {
    .content {
      display: grid;
      padding: 0 50px;
    }
  }