.payment_Container{
    width: 100%;
    height: 700px;
}
.rowView{
    display: flex;
}
.left{
    width: 16.5%;
}
.right{
    width: 100%;
}