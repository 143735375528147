.topbar {
    position: relative;
    width: 100%;
    height: 8vh;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    padding: 24px;
    transition: all 0.3s;
    align-items: center;
    box-shadow: 0px 1px 1px 0px #141111B5;
}

.topbar_logo {
    width: 200px;
    height: 75px;
    margin-left: 10px;
}

.rightside_view {
    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;
}

.topbar-date-icon {
    width: 28px;
    height: 28px;
}

.topbar-date-text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    padding-left: 15px;
}
.topbar-profile-icon{
    width: 32px;
    height: 32px;
    margin-left: 20px;
}