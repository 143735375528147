* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #ffffff;
}

.container {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 80vh;
}

.sidebar-out {
    position: relative;
    width: 300px;
    height: 90vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #fff;
    padding: 24px;
    margin-left: 10px;
    margin-top: 10px;
    border-radius: 10px;
    transition: all 0.3s;
    align-items: center;
    box-shadow: 0px 2px 4px 0px #141111B5;
}

.sidebar {
    position: relative;
    width: 256px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #fff;
    padding: 24px;
    margin-left: 10px;
    border-radius: 10px;
    transition: all 0.3s;
}

.sidebar {
    display: flex;
    gap: 20px;
    padding-bottom: 20px;
    box-shadow: 0px 2px 4px 0px #141111B5;
}

.menu ul li {
    position: relative;
    list-style: none;
    margin-top: 10px;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px 0px #141111B5;
    border-radius: 8px;
}

.menu ul li a {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #7446CE;
    text-decoration: none;
    padding: 12px 8px;
    border-radius: 8px;
    transition: all 0.3s;
    font-family: "Poppins";
    font-weight: 600;
}

.menu ul li>a:hover,
.menu ul li.active>a {
    color: #FFFFFF;
    background-color: #7446CE;
}

.dasboard_icon_style{
    width: 28px;
    height: 28px;
}
// .menu ul li .arrow {
//   font-size: 14px;
//   transition: all 0.3s;
// }
// .menu ul li.active .arrow {
//   transform: rotate(180deg);
// }
// .menu .sub-menu {
//   display: none;
//   margin-left: 20px;
//   padding-left: 20px;
//   padding-top: 5px;
//   border-left: 1px solid #f6f6f6;
// }
// .menu .sub-menu li a {
//   padding: 10px 8px;
//   font-size: 12px;
// }
// .menu:not(:last-child) {
//   padding-bottom: 10px;
//   margin-bottom: 20px;
//   border-bottom: 2px solid #f6f6f6;
// }
// .menu-btn {
//   position: absolute;
//   right: -14px;
//   top: 3.5%;
//   width: 28px;
//   height: 28px;
//   border-radius: 8px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   color: #757575;
//   border: 2px solid #f6f6f6;
//   background-color: #fff;
// }
// .menu-btn:hover i {
//   color: #000;
// }
// .menu-btn i {
//   transition: all 0.3s;
// }
// .sidebar.active {
//   width: 92px;
// }
// .sidebar.active .menu-btn i {
//   transform: rotate(180deg);
// }
// .sidebar.active .user-details {
//   display: none;
// }
// .sidebar.active .menu .title {
//   text-align: center;
// }
// .sidebar.active .menu ul li .arrow {
//   display: none;
// }
// .sidebar.active .menu > ul > li > a {
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// .sidebar.active .menu > ul > li > a .text {
//   position: absolute;
//   left: 70px;
//   top: 50%;
//   transform: translateY(-50%);
//   padding: 10px;
//   border-radius: 4px;
//   color: #fff;
//   background-color: #000;
//   opacity: 0;
//   visibility: hidden;
//   transition: all 0.3s;
// }
// .sidebar.active .menu > ul > li > a .text::after {
//   content: "";
//   position: absolute;
//   left: -5px;
//   top: 20%;
//   width: 20px;
//   height: 20px;
//   border-radius: 2px;
//   background-color: #000;
//   transform: rotate(45deg);
//   z-index: -1;
// }
// .sidebar.active .menu > ul > li > a:hover .text {
//   left: 50px;
//   opacity: 1;
//   visibility: visible;
// }
// .sidebar.active .menu .sub-menu {
//   position: absolute;
//   top: 0;
//   left: 20px;
//   width: 200px;
//   border-radius: 20px;
//   padding: 10px 20px;
//   border: 1px solid #f6f6f6;
//   background-color: #fff;
//   box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.1);
// }