*:after,
*:before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: sans-serif;
}

body {
    min-height: 100vh;
    background-color: #fff;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;
}

.order__left {
    order: 2;
    padding: 40px;
}

.order__right {
    order: 1;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.no__overflow {
    display: flex;
    align-items: center;
    overflow: hidden;
    
}

.astro_forgor_password {
    width: 100px;
    height: 100px;
    margin: auto;
}

.login-header-text {
    font-weight: 700;
    color: #451295;
    font-size: 30px;
    text-align: center;
    font-family: "Istok Web";
}

.login-label-text {
    font-weight: 700;
    color: #451295;
    font-size: 30px;
    font-family: "Istok Web";
}

.forgot-description-text {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
}

.login-input-view {
    width: Fixed (512px)px;
    height: Hug (53px)px;
    padding: 16px 14px 16px 14px;
    gap: 10px;
    border-radius: 10px 0px 0px 0px;
    border: 0.5px 0px 0px 0px;
    opacity: 0px;
}

.login-empty-text {
    color: white;
}

.form {
    max-width: 500px;
}

.logo {
    margin-bottom: 12px;
}

input[type=text],
input[type=password] {
    width: 100%;
    padding: 12px 16px;
    margin: 16px 0;
    display: block;
    border: 0.5px solid #CBCBCB;
    border-radius: 10px;
    background-color: #FFFFFF;
    font-size: 16px;
    box-shadow: 0px 2px 4px 0px #141111B5;
    color: #000000;
    outline: none;
    transition: box-shadow 0.25s ease-in-out, background-image 0.25s;
}

input[type=text]:focus,
input[type=password]:focus {
    background: #FAF8FD;
    background: #FDFCFE;
}

::-webkit-input-placeholder {
    color: #A29CA8;
}

:-ms-input-placeholder {
    color: #A29CA8;
}

::placeholder {
    color: #A29CA8;
}

.justify__space_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 26px 0;
}

input[type=checkbox],
label {
    margin-right: 4px;
    cursor: pointer;
    outline-color: #B595D4;
}

.remember_me {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: #000000;
}

.forgot__password {
    color: #7446CE;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
}

.forgot__password:active {
    color: #867992;
}

.login__button {
    outline-color: #451295;
    width: 100%;
    border: none;
    background-color: #7446CE;
    padding: 13px 17px;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: box-shadow 0.25s ease-in-out, background-color 0.3s;
}

.forgot__button {
    outline-color: #451295;
    width: 100%;
    border: 1px solid #7446CE;
    background-color: #FFFFFF;
    padding: 13px 17px;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition: box-shadow 0.25s ease-in-out, background-color 0.3s;
}

.login__text {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
}

.forgot__text {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: #7446CE;
}

.login__button:hover {
    box-shadow: 0 0 0 0.25rem #DAC5EE;
}

.login__button:active {
    background-color: #451295;
    box-shadow: 0 0 0 0.35rem #DAC5EE;
}

.signup {
    font-size: 14px;
    text-align: center;
    position: absolute;
    bottom: 20px;
    padding-left: 40px;
}

.img {
    height: 100%;
    object-fit: cover;
    max-width: auto;
}

@media only screen and (max-width: 800px) {
    .grid {
        grid-template-columns: auto;
    }

    .order__left {
        order: 2;
        padding: 20px;
    }

    .order__right {
        order: 1;
    }

    .centered {
        align-items: flex-start;
    }

    .no__overflow {
        align-items: flex-start;
    }

    h4 {
        text-align: center;
    }

    .img {
        width: 100vw;
    }
}

@media only screen and (max-height: 600px) {
    .img {
        width: 120%;
    }
}